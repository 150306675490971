import $ from 'jquery';
import Typed from 'typed.js';
import AOS from 'aos';
import './slick.js';
import '../styles/main.scss';

// scrollTo links
$(".js-scrollto").on('click', function(e){
    e.preventDefault();
    $('html,body').animate({ scrollTop:$(this.hash).offset().top }, 800);
});

// slick slider
$(".slider").slick({
    autoplay: true,
    dots: true,
	customPaging : function( slider, i ) {
		var title = $(slider.$slides[i]).find('.slide').attr('data-title');
        return '<button type="button">' + title + '</button>';
    },
	responsive: [
    {
      breakpoint: 768,
      settings: "unslick"
    }
  ]
});

// panel toggle
$( ".slide__toggle" ).click(function() {
    $(this).parent('.slide__panel').toggleClass( 'slide__panel--active' );
});

// typewriter effect
setTimeout(function(){

    var typed = new Typed('.js-typewriter--first', {
        strings: ["Learn &amp; Earn"],
        typeSpeed: 80,
        cursorChar: '_'
    });

    var typed = new Typed('.js-typewriter--second', {
        strings: ["Wie ben jij?"],
        typeSpeed: 80,
        cursorChar: '_'
    });

}, 2000);

// animate on scroll
AOS.init({
	offset: 200,
	duration: 1000,
    easing: 'ease'
});
